import loadEnotecaSettings from './settings/enoteca-settings';
import ProjectIdsEnum from './settings/ProjectIdsEnum';

const settings = [];

settings['project-id'] = ProjectIdsEnum.RafalaUk;
settings['provider'] = {
    name: 'Uderly',
    website: 'www.uderly.com',
    websiteUrl: 'https://www.uderly.com',
    contactFormUrl: 'https://www.uderly.com/#contact',
    logoPath: "/assets/images/uderly-32.png"
};
settings["project-name"] = "Rafala UK";
settings["sub-title"] = "Speciality Food & Wine";
settings["language"] = "en";
settings["currency"] = "£";
settings["logo-dark"] = "/assets/rafalauk/images/logo.svg";
settings["logo-light"] = "/assets/rafalauk/images/logo.svg";
settings["mini-logo-dark"] = "/assets/rafalauk/images/logo.svg";
settings["mini-logo-light"] = "/assets/rafalauk/images/logo.svg";
settings["placeholder"] = "/assets/rafalauk/images/logo.svg";
settings["password-min-length"] = 8;

settings['facebook-page-id'] = '103419424395830'; //'104696300956103';
settings['facebook-app-id'] = '2110358959097352';

settings["client-id"] = "2";
settings["client-secret"] = "0dIONiMg3HBDq6AID9dkFXxu8gNeWBQ6XLfxQtdu";
settings["api-url"] = "https://api.rafalauk.com/api/v1";
settings["api-base-url"] = "https://api.rafalauk.com";

settings["host-url"] = "http://" + settings["website"];

settings["facebook-page-url"] = "https://www.facebook.com/Rafalauk";
settings['instagram-page-url'] = 'https://www.instagram.com/rafala_uk/';

settings["google-maps-key"] = "AIzaSyBBduueLb6PfFIa5Z811RxdusHYeP3-u-I";

settings['phone-regex'] = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

settings["redux-auth-key"] = "rafala-auth-key";
settings["redux-cart-key"] = "rafala-cart-key";

settings['cover-path'] = 'assets/rafalauk/images/cover0.png';
settings['about-us'] = "Rafala UK";
settings['about-us-desc'] = <>
    <p>Rafala U.K. is a well-established Italian family business based in Burscough.</p>
    <p>We specialise in importing fine food and wine to the North West of the U.K in both wholesale and domestic capacities.</p>
    </>;
settings['phone'] = "01695 51335";
settings['email'] = "sales@rafalauk.com";

settings['shop-id'] = settings['project-id'];
settings['min-order'] = 15;
settings['max-delivery-cost'] = 10;
settings['primary-color'] = "#ee442b";
settings['secondary-color'] = "#f5bd4a";

settings['test-stripe-key'] = "pk_test_51Hw6EQJxO53uN3cAvX5OOZ1TxpGYsiYDW1hKrEbASNK4jfV4rr7HsPtvmNhdX17ycMjRCfThxNQqC5VpJ0qlRFkB007F0G3fe7";
settings['stripe-key'] = "pk_live_51Hw6EQJxO53uN3cAyGpC65zzHYDEFx2br7u59rrvMiHQJntyc3TWbz2uKgZIdpnNI2oBNDpud87GFSkyJjTgKxrJ00gHk2nz7E";

if(settings['project-id'] === ProjectIdsEnum.Enoteca)
    loadEnotecaSettings(settings);

export default settings;