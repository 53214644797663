import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect, shallowEqual, useSelector } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTheme } from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import headerStyle from "./headerStyle.js";

import * as auth from "app/modules/Auth/_redux/authRedux";
import * as cart from "app/modules/ECommerce/_redux/cart/cartActions";
import AppContext from "app/AppContext";
import MenuProApi from 'api/Rafala';
import FormImage from "framework/FormImage";
import {withModal} from 'framework/Modal';
import withOverlay from 'framework/withOverlay';
import Cart from "components/Cart/Cart";
import ToggleCartButton from "components/Cart/ToggleCartButton";
import { OrderTypesEnum } from 'components/Cart/Cart';
import PaymentInstructions from 'components/PaymentInstructions';

const useStyles = makeStyles(headerStyle);

const toggleDrawer = (open = null) => {
    if(extHandleDrawerToggle)
        extHandleDrawerToggle(open);
}

var extHandleDrawerToggle = null;

function Header(props) {
    const [userPopoverOpen, setUserPopoverOpen] = useState(false);
    
    const {user} = useSelector(
        ({auth}) => ({
            user: auth.user,
        }),
        shallowEqual
    );
    
    const onUserClick = (event) => {
        setUserPopoverOpen(!userPopoverOpen);
    };
        
    const onLogin = () => {
        if(props.authActions && props.authActions.onLogin)
            props.authActions.onLogin();
    }

    const onLogOut = () => {
        props.logout();
        setUserPopoverOpen(false);
    }
    
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const classes = useStyles();

    const { color, links, brand, fixed, absolute } = props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed
    });

    React.useEffect(() => {
        extHandleDrawerToggle = myHandleDrawerToggle;

        if (props.changeColorOnScroll) {
            if(window.location.pathname !== "/") {
                document.body.getElementsByTagName("header")[0].classList.add(classes[props.changeColorOnScroll.color]);
                document.body.getElementsByTagName("header")[0].classList.remove(classes[color]);
            } else {
                headerColorChange();
                window.addEventListener("scroll", headerColorChange);
            }
        }

        return function cleanup() {
            if (props.changeColorOnScroll && window.location.pathname === "/") {
                window.removeEventListener("scroll", headerColorChange);
            }
        };
    });
  
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const myHandleDrawerToggle = (open = null) => {
        if(open !== null)
            setMobileOpen(open);
        else
            setMobileOpen(!mobileOpen);
    };

    const onCheckedOut = () => {
        props.modal.setFree();
        props.modal.hide();

        // Erase Cart
        props.resetCart();
    }

    const checkout = async (cart, note, contactId = null, orderType = OrderTypesEnum.delivery, table = null, requestedDateTime = null, paymentTypeId = null, deliveryFee = 0, collectionShopId = null) => {
        // console.log(cart, note, contactId, orderType, deliveryFee);
        props.modal.setBusy();

        const order = {
            shop_order_type_id: orderType,
            note: note,
            contact_id: contactId,
            table: table,
            requested_date: requestedDateTime,
            payment_type_id: paymentTypeId,
            delivery_fee: deliveryFee,
            collection_shop_id: collectionShopId
        };
        
        order.items = [];
        
        // Expected format:
        // {
        //     "items": [
        //         { "shop_item_id": 10, "quantity": 1, "note": "ciao", "extras": "[]" }
        //     ],
        //     "note": "Per favore fate in fretta. No cipolla nel panino"
        // }

        for(let i = 0; i < cart.length; i++) {
            const itemExtras = [];
            
            for(let j = 0; j < cart[i].extras.length; j++)
                itemExtras.push(cart[i].extras[j].id);

            order.items.push({
                "shop_item_id": cart[i].product.id,
                "quantity": cart[i].quantity,
                "note": cart[i].note,
                "extras": JSON.stringify(itemExtras),
                "price": isNaN(cart[i].price) ? 0 : cart[i].price // Total price including extras
            });
        }

        const response = await MenuProApi.PlaceOrder(AppContext.s['shop-id'], order);
        console.log(AppContext.s['shop-id'], order, response);

        if(response && response.status === 201) {
            props.resetCart();

            const modalMessage = (
                <div className="checked-out-message">
                    <i className="far fa-check-circle icon"></i>
                    <p className="message">{AppContext.r["order-placed"]}</p>

                    <PaymentInstructions paymentTypeId={order.payment_type_id} orderTypeId={order.shop_order_type_id} />

                    <Button onClick={onCheckedOut}>{AppContext.r['close']}</Button>
                </div>);

            props.modal.setMessage(modalMessage, false);
        }
    }

    const showCart = () => {
        const modalView = (<Cart checkout={checkout} authActions={props.authActions} modal={props.modal}
            supportTakeAway={true} supportDelivery={true} enablePayment />); 

        props.modal.setView(AppContext.r["my-shopping-cart"], modalView, true, "cart-modal wide");
    }
    
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;

    if(window.location.pathname === "/") {
        const h = document.body.getElementsByTagName("header")[0];

        if (windowsScrollTop > changeColorOnScroll.height) {
            h.classList.remove(classes[color]);
            h.classList.add(classes[changeColorOnScroll.color]);
        } else {
            h.classList.add(classes[color]);
            h.classList.remove(classes[changeColorOnScroll.color]);
        }
    }
  };

  const avatar = (<div className="header-avatar" onClick={onUserClick}>
        <FormImage rounded viewOnly type="avatar" height={42}
            imageUrl={(user) ? AppContext.s['api-url'] + "/users/" + user.id + "/image" : ""} />
    </div>);

  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}>
            <Menu />
          </IconButton>
        </Hidden>

        <Button className={classes.title}>
            <NavLink to="/">{brand}</NavLink>
        </Button>

        <Hidden smDown implementation="css" className={classes.hidden}>
            <div className={classes.collapse}>{links}</div>
        </Hidden>

        { AppContext.enableCart ? (
            <>
                <ToggleCartButton onClick={showCart} />

                {user &&
                    <>
                        { userPopoverOpen &&
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart"
                                onClickAway={() => setUserPopoverOpen(false)}>
                                <div>
                                    <div className="user-popover">
                                        <h1>{user.name && user.name} {user.surname && user.surname}</h1>

                                        <Link to="/myaccount" onClick={() => setUserPopoverOpen(false)}>
                                            <i className="fas fa-user"></i> {AppContext.r['my-account']}
                                        </Link>

                                        <Link to="/orders" onClick={() => setUserPopoverOpen(false)}>
                                            <i className="far fa-list-alt"></i> {AppContext.r['my-orders']}
                                        </Link>
                                        
                                        <Button className="logout-button rounded-button" onClick={onLogOut}>
                                            <i className="fas fa-sign-out-alt"></i> {AppContext.r['logout']}
                                        </Button>
                                    </div>

                                    {avatar}
                                </div>
                            </ClickAwayListener>
                        }

                        { !userPopoverOpen && avatar }
                    </>
                }

                { !user && 
                    <Button color="transparent" onClick={onLogin} className="toggle-quick-user-button">
                        <i className={"fas fa-user"} />
                    </Button>
                }
            </>
        ) : "" }
            
        
      </Toolbar>

      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={handleDrawerToggle}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className={classes.closeButtonDrawer}>
            <Close />
          </IconButton>
          <div className={classes.appResponsive}>{links}</div>
        </Drawer>
      </Hidden>
    </AppBar>
  );
}

Header.defaultProp = {
    color: "white"
};

Header.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]),
    links: PropTypes.node,
    brand: PropTypes.any, //PropTypes.object | PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // props.color (see above)
    changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
        "primary",
        "info",
        "success",
        "warning",
        "danger",
        "transparent",
        "white",
        "rose",
        "dark"
    ]).isRequired
    })
};

export { toggleDrawer };
 
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        // cart: state.cart.cart,
        // cartUpdated: () => { return true }
    }
};

export default connect(mapStateToProps, { ...auth.actions, ...cart.actions })(withModal(withOverlay(withTheme(Header))));